export function isMediaPlayer(elem) {
  return !!elem.closest('.player3');
}

export function videoIsPlaying() {
  return window.player && window.player.getPlayerState &&
    !(window.player.getPlayerState() === YT.PlayerState.PAUSED ||
      window.player.getPlayerState() === YT.PlayerState.ENDED ||
      window.player.getPlayerState() === YT.PlayerState.UNSTARTED);
}

export function currentMediaPlaying() {
  const playButton = document.querySelector('.player3__action--play');
  return playButton?.dataset?.type;
}

export function progressBarLength(isMediaPlayer) {
  let progressBar = document.querySelector('.mini-progress-bar');
  if (isMediaPlayer) {
    progressBar = document.querySelector('.media-progress-bar');
  }
  if (!progressBar) return 0;
  return progressBar.parentNode.offsetWidth;
}

export function getCurrentProgressInPixels(time, isMediaPlayer, type) {
  let duration = window.player && window.player.getDuration ? window.player.getDuration() : 0;
  if (type === 'audio') {
    duration = window.audio ? window.audio.duration : 0;
  }
  const width = progressBarLength(isMediaPlayer);
  return time * width / duration;
}

export function getCurrentProgressInPercentage(time, isMediaPlayer, type) {
  return getCurrentProgressInPixels(time, isMediaPlayer, type) * 100 / progressBarLength(isMediaPlayer);
}

export function getCurrentProgressInSeconds(time) {
  return Math.round(time);
}

export function getCurrentProgressInSecondsFromPercentage(progress) {
  const duration = window.player && window.player.getDuration ? window.player.getDuration() : 0;
  return progress * duration / 100;
}

export function updateProgressBar(time, type) {
  document.querySelectorAll(`.progress-bar-${type}`).forEach(function(progressBar) {
    const currentWidth = getCurrentProgressInPixels(time, isMediaPlayer(progressBar), type);
    progressBar.style.width = currentWidth + 'px';
  });
}

export function updateTimeElapsed(time, type) {
  document.querySelectorAll(`.time-elapsed-${type}`).forEach(function(elem) {
    elem.innerText = getMinuteSecondsFormat(time);
  });
}

export function onProgressBarClick(e, isMediaPlayer, type) {
  if (currentMediaPlaying() !== type) return;

  const { layerX, target } = e;
  const duration = type === 'video' ? window.player.getDuration() : window.audio.duration;
  let seconds = Math.floor(layerX * duration / progressBarLength(isMediaPlayer));

  if (target.className.indexOf('playthrough__likes') > -1) {
    seconds = getCurrentProgressInSecondsFromPercentage(target.dataset.max)
  }
  if (target.id === 'amount-of-likes') {
    seconds = getCurrentProgressInSecondsFromPercentage(target.parentNode.dataset.max)
  }
  if (type === 'video') {
    window.player.seekTo(seconds, true);
  } else {
    window.audio.currentTime = seconds;
  }

  updateProgressBar(seconds, type);
  updateTimeElapsed(seconds, type);
}

export function getMinuteSecondsFormat(time) {
  const pad = '00';
  const minutes = Math.floor(time / 60);
  const seconds = (pad + Math.floor(time - minutes * 60).toString()).slice(-pad.length);
  return `${minutes}:${seconds}`;
}

export function hideMediaPlayer() {
  const mediaPlayer = document.getElementById('media-player');
  mediaPlayer.style.opacity = 0;
  // Set timeout needed to not break animation
  window.setTimeout(function() {
    mediaPlayer.classList.add('hidden');
  }, 1000);
}

export async function getMediaPlayerData(type, mediaId) {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const response = await window.fetch(`/media/${type}/${mediaId}/data`, {
    method: 'GET',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'same-origin'
  });
  const { image, song, artist, artist_url: artistUrl } = await response.json();
  return Promise.resolve({ image, song, artist, artistUrl });
}

export function updateMediaPlayerData({ mediaId, image, song, artist, artistUrl }) {
  document.querySelector('.player3__preview').closest('a').href = artistUrl;
  document.querySelector('.player3__preview').src = image;
  // It was decided to swap artist and song positions in the media player
  document.querySelector('.player3__song').innerText = artist;
  document.querySelector('.player3__artist').innerText = song;
  document.querySelector('.player-like-button').setAttribute('data-id', mediaId);
  document.querySelector('#media-player').setAttribute('data-id', mediaId);
}

export function resetProgressBar() {
  document.querySelectorAll('.progress-bar').forEach(function(progressBar) {
    progressBar.style.width = '0px';
  });
}

export function reactionElementAt(progress) {
  const nearFive = Math.floor(progress / 5) * 5;
  const element = document.querySelector(`.playthrough__likes[data-max='${nearFive}']`);
  return element;
}

function addHoverToStandUpEvent(element) {
  element.addEventListener('mouseenter', function() {
    element.style.zIndex = '5';
  });

  element.addEventListener('mouseleave', function() {
    element.style.zIndex = '1';
  })
}

function deleteAnimationAfterFinished(parentNode) {
  const hearts = parentNode.querySelectorAll('.playthrough__reactions');
  window.setTimeout(function() {
    hearts.forEach(function(heart) {
      heart.remove();
    });
  }, 3000)
}

function setLikesElementHeight({ element, increment }) {
  if (increment >= 10) {
    element.style.height = '27px';
    element.style.width = '27px';
    element.style.zIndex = '2';
  }
  if (increment >= 50) {
    element.style.height = '34px';
    element.style.width = '34px';
    element.style.zIndex = '3';
  }
  if (increment >= 100) {
    element.style.height = '42px';
    element.style.width = '42px';
    element.style.zIndex = '4';
  }
}

function incrementProgressBarLike(progress, noShow) {
  const nearFive = Math.floor(progress / 5) * 5;
  document.querySelectorAll(`.playthrough__likes[data-max='${nearFive}']`).forEach(function(element) {
    const amountOfLikesElement = element.querySelector('#amount-of-likes');
    const increment = parseInt(amountOfLikesElement.innerText) + 1;
    amountOfLikesElement.innerText = increment;
    setLikesElementHeight({ element, increment });

    if (noShow) return;
    const hearts = document.querySelector('.playthrough__reactions').cloneNode(true);
    hearts.classList.remove('hidden');
    element.appendChild(hearts);

    deleteAnimationAfterFinished(element);
  });
}

function addLikeElement(progress, noShow, type) {
  const nearFive = Math.floor(progress / 5) * 5;
  const likesElement = document.querySelector('.playthrough__likes').cloneNode(true);
  likesElement.style.left = `${nearFive}%`;
  likesElement.style.height = '20px';
  likesElement.style.width = '20px';
  likesElement.style.cursor = 'pointer';
  likesElement.classList.add('likes-cloned');
  likesElement.setAttribute('data-max', nearFive);
  likesElement.querySelector('#amount-of-likes').innerText = '1';

  addHoverToStandUpEvent(likesElement);

  if (!noShow) {
    const hearts = likesElement.querySelector('.playthrough__reactions');
    hearts.classList.remove('hidden');
    deleteAnimationAfterFinished(likesElement);
  }

  if (type === 'audio') {
    const progressBar = document.querySelector('#media-player .playthrough__bar');
    progressBar.appendChild(likesElement.cloneNode(true));
  } else {
    document.querySelectorAll('.playthrough__bar').forEach(function(progressBar) {
      progressBar.appendChild(likesElement.cloneNode(true));
    });
  }
}

export function setupLikes(time, noShow, isMediaPlayer, type = 'video') {
  const progress = getCurrentProgressInPercentage(time, isMediaPlayer, type);
  if (reactionElementAt(progress)) {
    incrementProgressBarLike(progress, noShow);
  } else {
    addLikeElement(progress, noShow, type);
  }
}

export function saveReaction(time, mediaId, type) {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const position = getCurrentProgressInSeconds(time);
  const mediaReaction = {
    position,
    amount: 1
  };
  if (type === 'video') {
    mediaReaction.video_id = mediaId;
  } else {
    mediaReaction.audio_id = mediaId;
  }
  window.fetch('/media_reactions', {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({ media_reaction: mediaReaction }),
    credentials: 'same-origin'
  });
}

export function resetReactions() {
  document.querySelectorAll('.likes-cloned').forEach(function(like) {
    like.remove();
  });
}

export async function loadReactions(mediaId, type, reactionLoaded) {
  if (reactionLoaded) return;

  resetReactions();
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const response = await window.fetch(`/media/${type}/${mediaId}/reactions`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'same-origin'
  });
  const { reactions = [] } = await response.json();
  reactionLoaded = true;
  reactions.forEach(function(reaction = {}) {
    const time = reaction.position || 0;
    setupLikes(time, true, true, type);
  });
}

export function pauseVideo() {
  if (window.player && window.player.pauseVideo) {
    window.player.pauseVideo();
  }
}

export function setPlayPauseIcons(audio) {
  audio = audio || window.audio;
  const id = audio.closest('#media-player').dataset.id;
  document.querySelectorAll('.media-card').forEach(function(card) {
    if (card.offsetWidth > 0 || card.offsetHeight > 0) {
      if (card.dataset.id !== id || (card.dataset.id === id && audio.paused)) {
        card.querySelector('.media-clip__icon--play').style.display = 'block';
        card.querySelector('.media-clip__icon--pause').style.display = 'none';
      } else {
        card.querySelector('.media-clip__icon--play').style.display = 'none';
        card.querySelector('.media-clip__icon--pause').style.display = 'block';
      }
    }
  });
}

export function isAudioPlaying() {
  const allAudios = document.querySelectorAll('audio');
  let isPlaying = false;
  for (let i = 0; i < allAudios.length; i++) {
    const audio = allAudios[i];
    if (!audio.paused) {
      isPlaying = true;
      break;
    }
  }
  return isPlaying;
}

export function stopAudios() {
  document.querySelectorAll('audio').forEach(function(audio) {
    if (audio && !audio.paused) {
      audio.pause();
      setPlayPauseIcons(audio);
    }
  });
}

export function playPreviousVideo() {
  const previous = document.querySelector('.media-row--current').previousElementSibling;
  if (previous) {
    previous.click();
    resetProgressBar();
    updateTimeElapsed(0, 'video');
  }

  checkIfHasPrevious(previous);
  checkIfHasNext(previous);
}

export function playNextVideo() {
  const next = document.querySelector('.media-row--current ~ a.media-row');
  if (next) {
    next.click();
    resetProgressBar();
    updateTimeElapsed(0, 'video');
  }

  checkIfHasPrevious(next);
  checkIfHasNext(next);
}

export function playPreviousAudio() {
  const button = window.audio.closest('.related-media__item');
  const previous = button ? button.previousElementSibling : null;
  if (previous) {
    previous.click();
    resetProgressBar();
    updateTimeElapsed(0, 'audio');
  }

  checkIfHasPrevious(previous);
  checkIfHasNext(previous);
}

export function playNextAudio(e) {
  const button = window.audio.closest('.related-media__item');
  const next = button ? button.nextElementSibling : null;
  if (next) {
    next.click();
    resetProgressBar();
    updateTimeElapsed(0, 'audio');
  }

  checkIfHasNext(next);
  checkIfHasPrevious(next);
}

export function checkIfHasPrevious(elem) {
  const previousButton = document.querySelector('.player3__action--backward');
  if (!previousButton) return;

  if (elem && elem.previousElementSibling) {
    previousButton.classList.remove('player3__action--disabled');
  } else {
    previousButton.classList.add('player3__action--disabled');
  }
}

export function checkIfHasNext(elem) {
  const nextButton = document.querySelector('.player3__action--forward');
  if (!nextButton) return;

  if (elem && elem.nextElementSibling) {
    nextButton.classList.remove('player3__action--disabled');
  } else {
    nextButton.classList.add('player3__action--disabled');
  }
}

export function checkPlayerPlayPauseIcon() {
  const button = document.querySelector('.player3__action--play');
  if (button.dataset.type === 'video') {
    if (videoIsPlaying()) {
      button.querySelector('.play-icon').classList.add('hidden');
      button.querySelector('.pause-icon').classList.remove('hidden');
    } else {
      button.querySelector('.play-icon').classList.remove('hidden');
      button.querySelector('.pause-icon').classList.add('hidden');
    }
  } else {
    if (window.audio && window.audio.paused) {
      button.querySelector('.play-icon').classList.remove('hidden');
      button.querySelector('.pause-icon').classList.add('hidden');
    } else {
      button.querySelector('.play-icon').classList.add('hidden');
      button.querySelector('.pause-icon').classList.remove('hidden');
    }
  }
}

export function addPlayPauseButtonEvent() {
  checkPlayerPlayPauseIcon();
  const button = document.querySelector('.player3__action--play');
  button.addEventListener('click', function() {
    if (button.dataset.type === 'video') {
      if (videoIsPlaying()) {
        window.player.pauseVideo();
      } else {
        window.player.playVideo();
      }
    } else {
      if (window.audio && window.audio.paused) {
        window.audio.play();
      } else {
        window.audio.pause();
      }
      setPlayPauseIcons(window.audio);
      checkPlayerPlayPauseIcon();
    }
  });
}

function addForwardsEvent() {
  const button = document.querySelector('.player3__action--forward');
  button.addEventListener('click', function(e) {
    if (currentMediaPlaying() === 'video') {
      playNextVideo(e);
    } else {
      playNextAudio(e);
    }
  });
}

function addBackwardsEvent() {
  const button = document.querySelector('.player3__action--backward');
  button.addEventListener('click', function(e) {
    if (currentMediaPlaying() === 'video') {
      playPreviousVideo(e);
    } else {
      playPreviousAudio(e);
    }
  });
}

function addLikeButtonEventListener() {
  const likeButton = document.querySelector('.player-like-button')
  likeButton.addEventListener('click', function(e) {
    const type = currentMediaPlaying();
    const currentTime = type === 'audio'
      ? window.audio.currentTime
      : window.player.getCurrentTime();
    const mediaId = e.currentTarget.dataset.id;
    setupLikes(currentTime, false, isMediaPlayer(likeButton), type);
    saveReaction(currentTime, mediaId, type);
  });
}

export function addMediaPlayerEvents(type) {
  const mediaPlayer = document.getElementById('media-player');
  if (type) {
    mediaPlayer.querySelector('.player3__action--play').setAttribute('data-type', type);
    mediaPlayer.querySelector('.player3__action--backward').setAttribute('data-type', type);
    mediaPlayer.querySelector('.player3__action--forward').setAttribute('data-type', type);
  }
  if (mediaPlayer.className.indexOf('hidden') > -1) {
    mediaPlayer.classList.remove('hidden');
    window.setTimeout(function() {
      // class has-player3 has to be added to html in order for the player to not be under the sidebar. Ask Anton if anything.
      document.getElementsByTagName('html')[0].classList.add('has-player3')
      mediaPlayer.style.opacity = 1;
    }, 200);

    addPlayPauseButtonEvent()
    addForwardsEvent();
    addBackwardsEvent();
    addLikeButtonEventListener();
  }
}
