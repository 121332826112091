import {
  isMediaPlayer,
  updateProgressBar,
  getCurrentProgressInPercentage,
  getMinuteSecondsFormat,
  addMediaPlayerEvents,
  resetProgressBar,
  resetReactions,
  saveReaction,
  isAudioPlaying,
  stopAudios,
  updateTimeElapsed,
  checkPlayerPlayPauseIcon,
  updateMediaPlayerData,
  onProgressBarClick,
  videoIsPlaying,
  checkIfHasNext,
  checkIfHasPrevious,
  reactionElementAt,
  setupLikes,
  loadReactions,
  getMediaPlayerData
} from '../utils/media';

window.player = null;
let customBarTimeInterval = null;
let currentTime = 0;
let currentVideoId = null;
let reactionLoaded = false;

window.getIdFromVideoLink = function(link) {
  if (link.indexOf('http') > -1) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = link.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }
  return link;
};

function addYoutubeIFrameAPI() {
  var tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function setPlayerProgressAnimation() {
  const animationInSeconds = 5;
  const intervalInMilliseconds = 50;
  const width = 188.5;
  const piece = intervalInMilliseconds * animationInSeconds / width;
  const circle = document.querySelector('#random-offset');
  let index = 1;
  if (circle) {
    const firstLoadAnimation = window.setInterval(function() {
      const dashOffset = piece * index;
      circle.setAttribute('stroke-dashoffset', width - dashOffset)
      index += 1;
      if (dashOffset >= 188.5) {
        clearInterval(firstLoadAnimation);
        circle.classList.add('hidden');
        document.querySelector('.player__grid').classList.add('hidden');
        document.querySelector('.player__image').classList.add('hidden');
        if (!isAudioPlaying()) {
          player.playVideo();
          addMediaPlayerEvents('video');
        }
      }
    }, intervalInMilliseconds);
  }
}

function checkIfHasReaction() {
  const progress = getCurrentProgressInPercentage(currentTime);
  const element = reactionElementAt(progress);
  if (element) {
    const hearts = element.querySelector('.playthrough__reactions');
    if (hearts) {
      hearts.classList.remove('hidden');
    }
  }
}

function YTDurationToSeconds(duration) {
  let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

  match = match.slice(1).map(function(x) {
    if (x != null) {
      return x.replace(/\D/, '');
    }
  });

  const hours = (parseInt(match[0]) || 0);
  const minutes = (parseInt(match[1]) || 0);
  const seconds = (parseInt(match[2]) || 0);

  return hours * 3600 + minutes * 60 + seconds;
}

function getTrackListVideoDuration() {
  document.querySelectorAll('.media-row').forEach(async function(row) {
    if (row && row.dataset && row.dataset.videoLink) {
      const youtubeId = getIdFromVideoLink(row.dataset.videoLink);
      const apiKey = document.querySelector('#youtube-video-player').dataset.apiKey;
      const response = await window.fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youtubeId}&key=${apiKey}&part=contentDetails`)
      const data = await response.json() || {};
      const { items = [] } = data;
      const item = items.length > 0 ? items[0] : {};
      const { contentDetails = {} } = item;
      const { duration } = contentDetails;
      const durationInSeconds = duration ? YTDurationToSeconds(duration) : null;
      row.querySelector('.media-row__time').innerText = getMinuteSecondsFormat(durationInSeconds);
    }
  })
}

function updateVideoDuration() {
  const duration = player.getDuration();
  document.querySelectorAll('.total-time').forEach(function(elem) {
    elem.innerText = getMinuteSecondsFormat(duration);
  });
}



function setVideoSelected(videoId) {
  videoId = videoId ? videoId : document.querySelector('#youtube-video-player').dataset.videoId;
  currentVideoId = videoId;
  const track = document.getElementById(`video-id-${videoId}`);
  if (track) track.classList.add('media-row--current');
}

function setTrackSelected(elem) {
  const currentRowSelected = document.querySelector('.media-row--current');
  if (currentRowSelected) {
    currentRowSelected.classList.remove('media-row--current');
  }
  elem.classList.add('media-row--current');

  addMediaPlayerEvents('video');

  getMediaPlayerData('Video', currentVideoId).then(function({ image, song, artist, artistUrl }) {
    updateMediaPlayerData({ image, artist, song, artistUrl, mediaId: currentVideoId });
  });

  checkIfHasNext(elem);
  checkIfHasPrevious(elem);
}

window.prepareForNewVideo = function(videoId) {
  const elem = document.querySelector(`#video-id-${videoId}`);
  currentVideoId = videoId;
  reactionLoaded = false;
  setTrackSelected(elem);
  resetReactions();
  resetProgressBar();
  getTrackListVideoDuration();
  $('#youtube-video-player').attr('data-video-id', videoId);
};

window.loadVideo = function(e) {
  e.preventDefault();
  const videoLink = e.currentTarget.dataset.videoLink;
  const videoId = e.currentTarget.dataset.videoId;
  currentVideoId = videoId;
  const youtubeId = getIdFromVideoLink(videoLink);
  player.loadVideoById(youtubeId);
  document.querySelector('#youtube-video-player')?.setAttribute('data-video-id', videoId);
  setTrackSelected(e.currentTarget);

  reactionLoaded = false;
  resetReactions();
  resetProgressBar();
};

function addTrackClickEvent() {
  document.querySelectorAll('.media-row').forEach(function(row) {
    row.addEventListener('click', loadVideo);
  })
}

function addLikeButtonEventListener() {
  const likeButton = document.querySelector('.video-player-like-button');
  if (!likeButton) return;

  likeButton.addEventListener('click', function() {
    setupLikes(currentTime, false, isMediaPlayer(likeButton));
    saveReaction(currentTime, currentVideoId, 'video');
  });
}

function addProgressBarClickEvent() {
  document.querySelectorAll('.playthrough__bar').forEach(function(progressBar) {
    progressBar.addEventListener('click', function(e) {
      onProgressBarClick(e, isMediaPlayer(progressBar), 'video');
    });
  });
}

function playNextVideo() {
  const next = document.querySelector('.media-row--current ~ a.media-row');
  if (next && next.dataset && next.dataset.videoLink) {
    const youtubeId = getIdFromVideoLink(next.dataset.videoLink);
    player.loadVideoById(youtubeId);

    currentVideoId = next.dataset.videoId;
    setTrackSelected(next);
    reactionLoaded = false;
    resetReactions();
    resetProgressBar();
  }
}

window.addNewVideo = function(props) {
  const { mediaId, height = 268, width = 435 } = props || {};
  const elementClassName = mediaId ? `youtube-video-player-${mediaId}` : 'youtube-video-player';
  let element = null;
  document.querySelectorAll(`.${elementClassName}`).forEach(function(el) {
    if (el.offsetWidth > 0 || el.closest(`.media-card-${mediaId}`).offsetWidth > 0) {
      element = el;
    }
  });
  const videoLink = element?.dataset?.videoLink;
  if (videoLink) {
    if (window.player) {
      window.player.pauseVideo();
    }

    const youtubeId = getIdFromVideoLink(videoLink);
    player = new YT.Player(element, {
      height,
      width,
      videoId: youtubeId,
      playerVars: {
        'playsinline': 1,
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  }
}

/* Youtube methods */
window.onYouTubeIframeAPIReady = function() {
  addNewVideo();
};

window.onPlayerReady = function(e) {
  updateVideoDuration();
  addLikeButtonEventListener();
  addProgressBarClickEvent();
  addTrackClickEvent();
  setVideoSelected(e.target.m.dataset?.videoId);
  getTrackListVideoDuration();

  const circle = document.querySelector('#random-offset');
  if (!circle) {
    window.player.playVideo();
  }
};

window.onPlayerStateChange = function(event) {
  clearInterval(customBarTimeInterval);
  if (event.data === YT.PlayerState.PLAYING) {
    let videoId = player.dataset ? player.dataset.videoId : currentVideoId
    if (currentVideoId !== videoId) {
      window.player.pauseVideo();
      currentVideoId = videoId;
      window.player = event.target;
    }
    reactionLoaded = false;
    loadReactions(currentVideoId, 'video', reactionLoaded);
    customBarTimeInterval = window.setInterval(function() {
      currentTime = player.getCurrentTime();
      updateProgressBar(currentTime, 'video');
      updateTimeElapsed(currentTime, 'video');
      checkIfHasReaction();
    }, 400);

    stopAudios();
    addMediaPlayerEvents('video');
    checkPlayerPlayPauseIcon();
    updateVideoDuration();
    getMediaPlayerData('Video', currentVideoId).then(function({ image, song, artist, artistUrl }) {
        updateMediaPlayerData({ image, artist, song, artistUrl, mediaId: currentVideoId });
    });
  }
  if (!videoIsPlaying()) {
    clearInterval(customBarTimeInterval);
    updateProgressBar(currentTime, 'video');
    updateTimeElapsed(currentTime, 'video');
    checkPlayerPlayPauseIcon();
  }
  if (event.data === YT.PlayerState.ENDED) {
    checkPlayerPlayPauseIcon();
    playNextVideo();
  }
};

addYoutubeIFrameAPI();
setPlayerProgressAnimation();
